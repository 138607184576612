<!--
 * @Author: your name
 * @Date: 2021-07-24 14:43:56
 * @LastEditTime: 2021-09-24 09:39:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth\components\choose.vue
-->
<template>
  <div class="choose">
    <div class="last" @click="last">上一步</div>
    <div class="title">身份选择</div>
    <p class="desc">
      恭喜您成为注册志愿者点击跳过即可进入个人中心。<br />
      我们免费为学生志愿者开放平台。<br />
      会员志愿者需先缴纳会员费才能认证成功。
    </p>
    <div class="select flex-h flex-hc">
      <div
        class="selectItem flex-v flex-hc flex-vc"
        :class="{ item: type == 1 }"
        @click="type = 1"
      >
        <img src="@/assets/img/auth1.png" />
        <span>学生志愿者</span>
      </div>
      <div
        class="selectItem flex-v flex-hc flex-vc"
        :class="{ item: type == 2 }"
        @click="type = 2"
      >
        <img src="@/assets/img/auth2.png" />
        <span>会员志愿者</span>
      </div>
    </div>
    <div class="buttons flex-h flex-vc flex-hc">
      <div class="button1" @click="$router.push({ name: 'home' })">跳 过</div>
      <div class="button2" @click="next">下一步</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    last() {
      // if (this.$route.query.status == 2) {
      //   this.$router.push({ path: "/user" });
      // } else {
      //   this.$router.push({ name: "login" });
      // }
      this.$router.go(-1);//返回上一层
    },
    next() {
      if (this.type == 1) {
        this.$bus.$emit("cutVoluntary", "Vstudent");
      }
      if (this.type == 2) {
        this.$bus.$emit("cutVoluntary", "Vmember");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.choose {
  width: 678px;
  height: 616px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 0px #c1bebe;
  border-radius: 20px;
  box-sizing: border-box;
  padding-top: 40px;
  position: relative;
  .last {
    cursor: pointer;
    font-size: 22px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    position: absolute;
    right: 35px;
  }
  .title {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ff6e6e;
    text-align: center;
  }
  .desc {
    width: 370px;
    height: 98px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #696969;
    line-height: 40px;
    margin: 0 auto;
    margin-top: 60px;
  }
  .select {
    margin-top: 65px;
    .selectItem {
      box-sizing: border-box;
      width: 181px;
      height: 141px;
      background: #f4f4f4;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 32px;
      img {
        width: 79px;
      }
      span {
        font-size: 16px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #555555;
        margin-top: 5px;
      }
    }
    .item {
      background: #ffffff;
      border-radius: 5px;
      border: 3px solid #ff6e6e;
    }
  }
  .buttons {
    margin-top: 60px;
    .button1 {
      width: 140px;
      height: 40px;
      border-radius: 24px;
      border: 1px solid #ff6e6e;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff6e6e;
      line-height: 40px;
      margin-right: 20px;
    }
    .button2 {
      width: 140px;
      height: 40px;
      background: #ff6e6e;
      border-radius: 24px;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 40px;
      margin-left: 20px;
    }
  }
}
</style>