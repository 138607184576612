<!--
 * @Author: your name
 * @Date: 2021-07-28 17:36:20
 * @LastEditTime: 2021-09-17 16:37:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth\components\success.vue
-->
<template>
    <div class="success flex-v flex-hc flex-vc">
        <img src="@/assets/img/success.png" />
        <span>提交成功</span>
        <p>{{ affirm==1?'我们将在3个工作日为您进行身份审核，请耐心等待~':''}}</p>
        <div v-if="$route.query.status == 2" class="button" @click="$router.push({path: '/user' })">返回个人中心</div>
        <div v-if="$route.query.status != 2" class="button" @click="$router.push({name:'home'})">返回首页</div>
    </div>
</template>
<script>
export default {
  data() {
    return {};
  },
    computed: {
  
    affirm() {
      return this.$store.state.affirm;
    },
  },
};
</script>
<style lang="scss" scoped>
.success {
  width: 433px;
  height: 275px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px #d3d3d3;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  span {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #696969;
    margin-top: 12px;
    
  }
  .button {
    width: 140px;
    height: 40px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 40px;
  }
  p{
     font-size: 16px;
   
    color: #696969;
    margin: 20px 0;
  }
}
</style>