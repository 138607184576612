<!--
 * @Author: your name
 * @Date: 2021-07-28 11:38:09
 * @LastEditTime: 2021-09-25 15:19:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth\components\v_student.vue
-->
<template>
  <div class="v_member">
    <div class="last" @click="last">返回</div>
    <div class="title">会员志愿者认证</div>
    　<el-scrollbar>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
        style="width: 650px; padding-bottom: 50px"
        size="small"
      >
        <el-form-item label="真实姓名" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="真实姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="idType">
          <el-select v-model="ruleForm.idType" placeholder="请选择证件类型">
            <el-option label="居民身份证" :value="1"></el-option>
            <el-option label="香港居民身份证" :value="2"></el-option>
            <el-option label="澳门居民身份证" :value="3"></el-option>
            <el-option label="台湾身份证" :value="4"></el-option>
            <el-option label="护照" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="is-required" label="证件号码" prop="idNumber">
          <el-input
            v-model="ruleForm.idNumber"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="证件号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传照片" prop="visualHeadImg">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/privateUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.visualHeadImg"
              :src="ruleForm.visualHeadImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传本人正面照。图片必须清晰符合打印条件。照片会应用于志愿者证书证件照、服务证明证件照。建议尺寸：250*350
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="ruleForm.birthday"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="民族" prop="race">
          <el-input
            v-model="ruleForm.race"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入民族名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="政治面貌" prop="politicCountenance">
          <el-input
            v-model="ruleForm.politicCountenance"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入政治面貌"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-select v-model="ruleForm.education" placeholder="请选择学历">
            <el-option label="博士" :value="7"></el-option>
            <el-option label="硕士" :value="6"></el-option>
            <el-option label="本科" :value="5"></el-option>
            <el-option label="专科" :value="4"></el-option>
            <el-option label="高中" :value="3"></el-option>
            <el-option label="初中" :value="2"></el-option>
            <el-option label="小学" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="健康状况">
          <el-input
            v-model="ruleForm.health"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入健康状况"
          ></el-input>
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input
            v-model="ruleForm.email"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入电子邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="户籍地址" prop="location">
          <el-cascader
            :options="mapOptions"
            v-model="ruleForm.location"
            :props="{ value: 'label' }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="上传身份证正面" prop="idPositiveImg">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/privateUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleZmSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.idPositiveImg"
              :src="ruleForm.idPositiveImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">（上传正面照）</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="上传身份证背面" prop="idSideImg">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/privateUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleBmSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.idSideImg"
              :src="ruleForm.idSideImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">（上传背面照）</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="户籍详细地址" prop="address">
          <el-input
            v-model="ruleForm.address"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入户籍详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="通讯地址" prop="message">
          <el-cascader
            :options="mapOptions"
            v-model="ruleForm.message"
            :props="{ value: 'label' }"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="通讯详细地址" prop="messageAddress">
          <el-input
            v-model="ruleForm.messageAddress"
            maxlength="100"
            show-word-limit
            clearable
            placeholder="请输入通讯详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮编" prop="postcode">
          <el-input
            v-model="ruleForm.postcode"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入邮编"
          ></el-input>
        </el-form-item>
        <el-form-item label="现工作单位" prop="companyName">
          <el-input
            v-model="ruleForm.companyName"
            maxlength="30"
            show-word-limit
            clearable
            placeholder="请输入现工作单位"
          ></el-input>
        </el-form-item>
        <el-form-item label="职务/职称" prop="position">
          <el-input
            v-model="ruleForm.position"
            maxlength="30"
            show-word-limit
            clearable
            placeholder="请输入职务/职称"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作单位性质">
          <el-select v-model="ruleForm.companyNature" placeholder="请选择">
            <el-option label="政府机关单位" :value="1"></el-option>
            <el-option label="事业单位和国有企业" :value="2"></el-option>
            <el-option label="外资企业" :value="3"></el-option>
            <el-option label="私营企业" :value="4"></el-option>
            <el-option label="社会团体" :value="5"></el-option>
            <el-option label="境外机构" :value="6"></el-option>
            <el-option label="其他" :value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="从事何种职业" prop="occupation">
          <el-input
            v-model="ruleForm.occupation"
            maxlength="30"
            show-word-limit
            clearable
            placeholder="请输入职业"
          ></el-input>
        </el-form-item>
        <el-form-item label="有何种技能和特长" prop="specialty">
          <el-input
            type="textarea"
            v-model="ruleForm.specialty"
            maxlength="100"
            show-word-limit
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="志愿服务意向" prop="intention">
          <el-input
            type="textarea"
            v-model="ruleForm.intention"
            maxlength="300"
            show-word-limit
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>

        <div class="button" @click="submitForm('ruleForm')">提 交</div>
      </el-form>
    </el-scrollbar>
  </div>
</template>
<script>
import { getToken, getUserInfo, setUserInfo } from "@/utils/auth";
import { applyMemberInfo, memberInfo, getUser } from "@/api/index";
import IdentityCodeValid from "@/utils/checkIdent";
import options from "@/utils/country-level3-data";
export default {
  data() {
    var checkIdNumber = (rule, value, callback) => {
      if (this.ruleForm.idType == 1) {
        var res = IdentityCodeValid(value);
        if (res) {
          callback();
        } else {
          callback(new Error("身份证号格式错误"));
        }
      } else {
        if (value === "") {
          callback(new Error("请输入证件号码"));
        }else{
          callback()
        }
      }
    };
    return {
      type: 1,
      headers: {
        token: getToken(),
      },
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
      },
      headImg: "",
      idPositiveImg: "",
      idSideImg: "",
      ruleForm: {
        userName: "",
        idType: "",
        idNumber: "",
        visualHeadImg: "",
        sex: 1,
        birthday: "",
        race: "",
        politicCountenance: "",
        idPositiveImg: "",
        idSideImg: "",
        education: "",
        health: "",
        email: "",
        location: [],
        address: "",
        message: [],
        messageAddress: "",
        postcode: "",
        companyName: "",
        position: "",
        companyNature: "",
        occupation: "",
        specialty: "",
        intention: "",
      },
      mapOptions: options,
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        idNumber: [{ validator: checkIdNumber, trigger: "blur" }],
        visualHeadImg: [
          { required: true, message: "请上传证件照", trigger: "blur" },
        ],
        idPositiveImg: [
          { required: true, message: "请上传正面", trigger: "blur" },
        ],
        idSideImg: [{ required: true, message: "请上传反面", trigger: "blur" }],
        birthday: [{ required: true, message: "请选择时间", trigger: "blur" }],

        race: [{ required: true, message: "请输入民族名称", trigger: "blur" }],
        politicCountenance: [
          { required: true, message: "请输入政治面貌", trigger: "blur" },
        ],
        education: [
          { required: true, message: "请选择学历", trigger: "change" },
        ],
        email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
        location: [
          { required: true, message: "请输入户籍地址", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        message: [
          { required: true, message: "请输入通讯地址", trigger: "change" },
        ],
        messageAddress: [
          { required: true, message: "请输入通讯详细地址", trigger: "blur" },
        ],
        postcode: [{ required: true, message: "请输入邮编", trigger: "blur" }],
        companyName: [
          { required: true, message: "请输入现工作单位", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请输入职务/职称", trigger: "blur" },
        ],
        occupation: [
          { required: true, message: "请输入职业", trigger: "blur" },
        ],
        specialty: [{ required: true, message: "请输入内容", trigger: "blur" }],
        intention: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    last() {
      if (this.$route.query.UT == 3) {
        this.$router.push({ path: "/user" });
      } else {
        this.$bus.$emit("cutVoluntary", "choose");
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          data.userName = this.ruleForm.userName;
          data.idType = this.ruleForm.idType;
          data.idNumber = this.ruleForm.idNumber;
          data.headImg = this.headImg;
          data.sex = this.ruleForm.sex;
          data.birthday = this.ruleForm.birthday / 1000;
          data.race = this.ruleForm.race;
          data.politicCountenance = this.ruleForm.politicCountenance;
          data.education = this.ruleForm.education;
          data.health = this.ruleForm.health;
          data.email = this.ruleForm.email;
          data.nativeAddress = {
            address: this.ruleForm.address,
            province: this.ruleForm.location[0],
            city: this.ruleForm.location[1],
            area: this.ruleForm.location[2],
          };
          data.liveAddress = {
            address: this.ruleForm.messageAddress,
            province: this.ruleForm.message[0],
            city: this.ruleForm.message[1],
            area: this.ruleForm.message[2],
          };
          data.postcode = this.ruleForm.postcode;
          data.companyName = this.ruleForm.companyName;
          data.position = this.ruleForm.position;
          data.companyNature = this.ruleForm.companyNature;
          data.occupation = this.ruleForm.occupation;
          data.specialty = this.ruleForm.specialty;
          data.intention = this.ruleForm.intention;
          data.idPositiveImg = this.idPositiveImg;
          data.idSideImg = this.idSideImg;
          applyMemberInfo(data).then((res) => {
            this.$bus.$emit("cutVoluntary", "success");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.visualHeadImg = res.data.visualUrl;
      this.headImg = res.data.privateUrl;
    },
    handleZmSuccess(res, file) {
      this.ruleForm.idPositiveImg = res.data.visualUrl;
      this.idPositiveImg = res.data.privateUrl;
    },
    handleBmSuccess(res, file) {
      this.ruleForm.idSideImg = res.data.visualUrl;
      this.idSideImg = res.data.privateUrl;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 2048;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2M!");
        return false;
      }
      return true;
    },
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 300;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 300K!");
        return false;
      }
      return true;
    },
    getData() {
      memberInfo({
        encryptionId: this.userId,
      }).then((res) => {
        console.log(res.data);
        this.ruleForm = res.data;
        this.ruleForm.birthday = this.ruleForm.birthday * 1000;
        this.headImg = this.ruleForm.privateHeadImg;
        let nativeAddress = this.ruleForm.nativeAddress || {};
        let liveAddress = this.ruleForm.liveAddress || {};
        this.ruleForm.location = [
          nativeAddress.province,
          nativeAddress.city,
          nativeAddress.area,
        ];
        this.ruleForm.address = nativeAddress.address;
        this.ruleForm.message = [
          liveAddress.province,
          liveAddress.city,
          liveAddress.area,
        ];
        this.ruleForm.messageAddress = liveAddress.address;
        this.idPositiveImg = this.ruleForm.privateIdPositiveImg;
        this.idSideImg = this.ruleForm.privateIdSideImg;
      });
    },
  },
  created() {
    if (this.$route.query.UT == 3) {
      this.getData();
    } else {
      getUser().then((res) => {
        this.ruleForm.userName = res.data.userName;
        this.ruleForm.idType = res.data.idType;
        this.ruleForm.idNumber = res.data.idNumber;
        this.ruleForm.visualHeadImg = res.data.visualHeadImg;
        this.headImg = res.data.privateHeadImg;
      });
    }
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
};
</script>
<style lang="scss">
.v_member {
  width: 877px;
  height: 819px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(156, 156, 156, 0.5);
  border-radius: 20px;
  box-sizing: border-box;
  padding-top: 40px;
  position: relative;

  .last {
    cursor: pointer;
    font-size: 22px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    position: absolute;
    right: 35px;
  }
  .title {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ff6e6e;
    text-align: center;
    margin-bottom: 40px;
  }

  .button {
    width: 140px;
    height: 40px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 40px;
    margin-top: 80px;
    margin-left: 320px;
  }
  .el-scrollbar__wrap {
    height: 700px;
  }
}
</style>