<!--
 * @Author: your name
 * @Date: 2021-07-28 11:38:09
 * @LastEditTime: 2021-11-09 11:17:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth\components\v_student.vue
-->
<template>
  <div class="v_student">
    <div class="last" @click="last">返回</div>
    <div class="title">学生志愿者认证</div>
    　<el-scrollbar>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
        style="width: 650px; padding-bottom: 50px"
        size="small"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            v-model="ruleForm.userName"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="idType">
          <el-select v-model="ruleForm.idType" placeholder="请选择证件类型">
            <el-option label="居民身份证" :value="1"></el-option>
            <el-option label="香港居民身份证" :value="2"></el-option>
            <el-option label="澳门居民身份证" :value="3"></el-option>
            <el-option label="台湾身份证" :value="4"></el-option>
            <el-option label="护照" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="is-required" label="证件号码" prop="idNumber">
          <el-input
            v-model="ruleForm.idNumber"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="证件号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传照片" prop="visualHeadImg">
          <el-upload
            class="avatar-uploader"
            :action="`${$baseURL}/file/privateUpload`"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.visualHeadImg"
              :src="ruleForm.visualHeadImg"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传本人正面照。图片必须清晰符合打印条件。照片会应用于志愿者证书证件照、服务证明证件照。建议尺寸：250*350
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="ruleForm.birthday"
            type="date"
            value-format="timestamp"
            placeholder="选择日期时间"
            :picker-options="expireTimeOption"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="学生身份证明" prop="liveStreamRemarkImgs">
          <el-upload
            list-type="picture-card"
            :action="`${$baseURL}/file/privateUpload`"
            :headers="headers"
            :on-preview="handlePictureCardPreview"
            :on-success="handleSuccess"
            :on-remove="handleRemove"
            :file-list="ruleForm.liveStreamRemarkImgs"
            :before-upload="beforeUpload"
            :limit="10"
          >
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">
              （可上传多张资质，证明包括但不限于学生证、身份证、户口本等）
            </div>
          </el-upload>
          <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :append-to-body="true"
          >
            <img width="100%" :src="dialogImageUrl" alt />
          </el-dialog>
        </el-form-item>
        <el-form-item label="民族" prop="race">
          <el-input
            v-model="ruleForm.race"
            maxlength="20"
            show-word-limit
            clearable
            placeholder="请输入民族名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="监护人姓名" prop="guardian">
          <el-input
            v-model="ruleForm.guardian"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="请输入监护人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="监护人身份" prop="guardianType">
          <el-input
            v-model="ruleForm.guardianType"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="请输入监护人身份"
          ></el-input>
        </el-form-item>
        <el-form-item label="监护人手机号" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="请输入手机号"
            maxlength="11"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>

        <div class="button" @click="submitForm('ruleForm')">提 交</div>
      </el-form>
    </el-scrollbar>
  </div>
</template>
<script>
import { getToken, getUserInfo, setUserInfo } from "@/utils/auth";
import { applyStudentInfo, studentInfo, getUser } from "@/api/index";
import IdentityCodeValid from "@/utils/checkIdent";
const validatePhone = (rule, value, callback) => {
  if (!/^1\d{10}$/.test(value)) {
    callback(new Error("请输入正确格式手机号码"));
  } else {
    callback();
  }
};

export default {
  data() {
    var checkIdNumber = (rule, value, callback) => {
      if (this.ruleForm.idType == 1) {
        var res = IdentityCodeValid(value);
        if (res) {
          callback();
        } else {
          callback(new Error("身份证号格式错误"));
        }
      } else {
        if (value === "") {
          callback(new Error("请输入证件号码"));
        }else{
          callback()
        }
      }
    };
    return {
      type: 1,
      headers: {
        token: getToken(),
      },
      expireTimeOption: {
        disabledDate(date) {
          //disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
          return date.getTime() > Date.now();
        },
      },
      headImg: "",
      ruleForm: {
        userName: "",
        idType: "",
        idNumber: "",
        visualHeadImg: "",
        liveStreamRemarkImgs: [],
        sex: 1,
        birthday: "",
        race: "",
        guardian: "",
        guardianType: "",
        contactPhone: "",
      },

      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        idType: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        idNumber: [{ validator: checkIdNumber, trigger: "blur" }],
        visualHeadImg: [
          { required: true, message: "请上传证件照", trigger: "blur" },
        ],
        liveStreamRemarkImgs: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
        birthday: [{ required: true, message: "请选择时间", trigger: "blur" }],
        race: [{ required: true, message: "请输入民族名称", trigger: "blur" }],
        guardian: [
          { required: true, message: "请输入监护人姓名", trigger: "blur" },
        ],
        guardianType: [
          { required: true, message: "请输入监护人身份", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
      },
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    getData() {
      studentInfo({
        encryptionId: this.userId,
      }).then((res) => {
        this.ruleForm = res.data;
        this.ruleForm.birthday = this.ruleForm.birthday * 1000;
        this.headImg = this.ruleForm.privateHeadImg;
        let idImgList = this.ruleForm.idImgList;
        let privateIdImgList = this.ruleForm.privateIdImgList;
        this.ruleForm.liveStreamRemarkImgs = idImgList.map((item, index) => {
          return {
            url: item,
            privateUrl: privateIdImgList[index],
          };
        });
      });
    },
    last() {
      if (this.$route.query.UT == 2) {
        this.$router.push({ path: "/user" });
      } else {
        this.$bus.$emit("cutVoluntary", "choose");
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {};
          data.userName = this.ruleForm.userName;
          data.idType = this.ruleForm.idType;
          data.idNumber = this.ruleForm.idNumber;
          console.log(this.headImg);
          data.headImg = this.headImg;
          data.sex = this.ruleForm.sex;
          data.birthday = this.ruleForm.birthday / 1000;
          data.idImg = this.ruleForm.liveStreamRemarkImgs.map((item) => {
            return item.privateUrl;
          });
          data.race = this.ruleForm.race;
          data.guardian = this.ruleForm.guardian;
          data.guardianType = this.ruleForm.guardianType;
          data.contactPhone = this.ruleForm.contactPhone;
          applyStudentInfo(data).then((res) => {
            this.$bus.$emit("cutVoluntary", "success");
          });
          const hasUserInfo = getUserInfo();
          hasUserInfo.userType = 2;
          setUserInfo(hasUserInfo);
          this.$store.commit("login", hasUserInfo);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.visualHeadImg = res.data.visualUrl;
      this.headImg = res.data.privateUrl;
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 1024*3;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 3M!");
        return false;
      }
      return true;
    },
    beforeUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 < 1024*8;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG/PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 8M!");
        return false;
      }
      return true;
    },
    handleSuccess(res, file, fileList) {
      this.ruleForm.liveStreamRemarkImgs.push({
        url: res.data.visualUrl,
        privateUrl: res.data.privateUrl,
      });
    },
    handleRemove(file, fileList) {
      var _tmp = this.ruleForm.liveStreamRemarkImgs;
      for (var i = 0, len = _tmp.length; i < len; i++) {
        if (_tmp[i].url == file.url) {
          _tmp.splice(i, 1);
          break;
        }
      }
      this.ruleForm.liveStreamRemarkImgs = _tmp;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
  created() {
    if (this.$route.query.UT == 2) {
      this.getData();
    } else {
      getUser().then((res) => {
        this.ruleForm.userName = res.data.userName;
        this.ruleForm.idType = res.data.idType;
        this.ruleForm.idNumber = res.data.idNumber;
        this.ruleForm.visualHeadImg = res.data.visualHeadImg;
        this.headImg = res.data.privateHeadImg;
      });
    }
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
};
</script>
<style lang="scss">
.v_student {
  width: 877px;
  height: 819px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(156, 156, 156, 0.5);
  border-radius: 20px;
  box-sizing: border-box;
  padding-top: 40px;
  position: relative;
  .last {
    cursor: pointer;
    font-size: 22px;
    font-family: MicrosoftYaHei;
    color: #ff6e6e;
    position: absolute;
    right: 35px;
  }
  .title {
    font-size: 24px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ff6e6e;
    text-align: center;
    margin-bottom: 40px;
  }

  .button {
    width: 140px;
    height: 40px;
    background: #ff6e6e;
    border-radius: 24px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #ffffff;
    line-height: 40px;
    margin-top: 80px;
    margin-left: 320px;
  }
  .el-scrollbar__wrap {
    height: 700px;
  }
}
</style>