<!--
 * @Author: your name
 * @Date: 2021-07-24 11:20:37
 * @LastEditTime: 2021-09-17 17:22:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\auth.vue
-->
<template>
  <div class="auth" :style="{ height: $store.state.clientHeight + 'px' }">
    <img class="logo" src="@/assets/img/logo.png" />
    <div class="vessel">
      <aside :is="component"></aside>
    </div>
  </div>
</template>
<script>
import choose from "./components/choose.vue";
import Vstudent from "./components/v_student.vue";
import Vmember from "./components/v_member.vue";
import success from "./components/success.vue";
export default {
  data() {
    return {
      component: "choose",
    };
  },
  components: { choose, Vstudent, Vmember, success },
  mounted() {
    this.$bus.$on("cutVoluntary", (data) => {
      this.component = data;
    });
    if (this.$route.query.UT == 2) {
      this.component = "Vstudent";
    }
    if (this.$route.query.UT == 3) {
      this.component = "Vmember";
    }
  },
};
</script>
<style lang="scss" scoped>
.auth {
  background: url("../../assets/img/authBG.png") right bottom;
  position: relative;
  .logo {
    width: 232px;
    position: absolute;
    top: 47px;
    left: 15%;
  }
  .vessel {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>